@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

// Fonts
@include foundation-prototype-font-styling;


/* ====================================================
    Global
   ==================================================== */
body {
	font-size: 18px;
	line-height: 1.1em;
	//background: #fff;
    max-width: 1440px;
	margin: auto;
	position: relative;
}

p, li {
	line-height: 1.2em;
    color: $primary-color;
}

li {
	margin-bottom: 1em;
}

h1 {
	font-family: 'Roboto Condensed', sans-serif;
	color: $secondary-color;
	font-weight: 700;
	line-height: 1.1em;
	font-size: 30px;
	margin-bottom: 25px;
}

.savings-h1 {
	font-size: 2.5rem;
    color: #88E3EB;
    text-shadow: 0px 2px 12px #000;

	span {
		color: $orange;
		font-size: 3.5rem;
		line-height: 1.2;
	}
}

.bordered-card {
	padding: 20px 20px 50px;
	border: 1px $green dashed;
    background-color: rgba(0, 124, 133, 0.75);
	margin-bottom: 40px;
    width: 80%;
    text-align: left!important;
    
    .customCheckBox[type="checkbox"]:after{
        position: relative;
        display: block;
        left: 2px;
        top: -11px;
        width: 7px;
        height: 7px;
        border-width: 1px;
        border-style: solid;
        border-color: #B3B3B3 #dcddde #dcddde #B3B3B3;
        content: "";
        background-repeat: no-repeat;
        background-position:center;
    }

    .customCheckBox[type="checkbox"]:before{
        border: 1px solid #808080;
        content: "";
        background: #FFF;
        position: relative;
        display: block;
        width: 11px;
        height: 11px;

    }
    .customCheckBox[type="checkbox"]:checked:after{
         background-image: url('../img/check.jpg'); 
            background-repeat: no-repeat; 
            background-position: top center;
            background-size:100% auto;
       
    }
    /* Large checkboxes */
    .customCheckBox.large{
        height:22px;
        width: 22px;
        margin:10px 0px 0px 10px
    }

    .customCheckBox.large[type="checkbox"]:before{
        width: 20px;
        height: 20px;
    }
    .customCheckBox.large[type="checkbox"]:after{
        top: -20px;
        width: 16px;
        height: 16px;
    }
	p {
	font-size:1em; 
        padding:10px;
        color:#FFFFFF;
	}

	button {
		float: right;
        background-color: #FF9D28;
        padding: 10px 30px;
		color: #000;
		
		&:disabled {
			background-color:#B6B6B6;
		}
	}
}

h2 {
	color: $primary-color;
	font-weight: bold;
	line-height: 1.1em;
	font-size: 24px;

	&.title {
		font-family: 'Roboto Condensed', sans-serif;
		font-weight: 700;
		font-size: 26px;
	}
}

h3 {
	font-weight:bold;
	font-size: 20px;
	color:#69B8BF;
}

h4 {
	font-family: 'Roboto Condensed', sans-serif;
	color: $secondary-color;
	font-weight: 700;
	font-size: 20px;
}

h5 {
	font-family: 'Roboto Condensed', sans-serif;
	color: $secondary-color;
	font-weight: 700;
	font-size: 22px;
}

h6 {
	color: $primary-color;
	font-weight: bold;
	font-size: 16px;
}

a {
	color: $secondary-color;
	text-decoration: underline;

	&:hover{
		color: #811C1C;
	}
}

ul {
	color: $primary-color;

	span {
		color: $black;
	}
}

sup {
	line-height:0;
	top: -0.4em;
	font-size:60%;
}

strong {
	font-weight: 700;
}

small {
	line-height: 0.5em;
}

.container-spacing {
	padding-top: 40px;
	padding-bottom: 40px;
}

.display-flex {
	display: flex;
}

.watermark {
	background-image: url('../img/logo_watermark.svg'); 
	background-repeat: no-repeat; 
	background-position: center center;
}

.fcolor {
	color:$primary-color;
}

.fcolor-gray {
	color: #666;
}

.text-green {
	color: $green;

	p {
		color: $green;
	}
}

.phone {
	font-weight: 900;
	font-size: 14px;
}

.text-top-black {
	color: $top-black;

	p, a {
		color: $top-black;
	}
}

.whitebg {
	background-color: #fff!important;
}

.color-carepath {
	color:#003479;
}

.footnote{
	font-size: 14px;

	p{
		text-indent: -5px;
		margin-left: 5px;
		margin-bottom: 10px;
	}
}

.bl{
    border-right:#F9AA36 2px solid;
    padding-right: 10px;
    &.g{
       border-right:#2b878c 2px solid;  
        
    }
}
.ul-sub {
	margin-top: 1em;
}

.vAlign .cell{
	margin-top: auto;
	margin-bottom: auto;
}

.shorten-p-1 {
	//width: 78%;
}

.shorten-p-2 {
	width: 89%;
}

.mBottom0 {
	margin-bottom: 0;
}

.mtop20 {
	margin-top: 20px;
}
.color-blocks {
	background-color: #309097;
	padding:10px 10px 35px 10px;
	height:100%;
	position: relative;
	
	h4 {
		color:#FFFFFF;
	}

	p {
		color:#eefeda; 
	}

	.externalLink {
		background-color: #f7941d;
		font-size: .7em;
		border-radius: 15px;
		padding:5px 15px;
		float: right;
		color:#FFFFFF!important;
		position: absolute;
		bottom:10px;
		right:10px;
		text-decoration: none;
	}
}

.adult-resources {
	padding: 40px 7.5rem !important;
}

.sitemap {
	padding-bottom: 10rem;
}

.site_map {
	li h3 a {
		color: $primary-color;
		text-decoration: none;
	}

	li a {
		color:#000;
	}

}

.thumbnail {
	border: 4px solid #69B8BF;
}

.nolinkline {
	text-decoration: none;
}


/* ====================================================
    Social Sharing Menu
   ==================================================== */
header .top-nav-area .top-nav .menu a.social-share {
	background-color: #fff;
	color:$primary-color;
	font-weight: bold;
	border-radius: 20px;
	text-transform: uppercase;
	font-size: 13px;
	padding: 3px 9px;
	margin-top: -2px;

	.share-icon{
		width: 17px;
	}
}

.topsmNav {
	a {
		&.active {
			color: $orange !important;
		}
	}
}

.dropdown.menu > li.is-dropdown-submenu-parent > a.social-share::after {
	border: none;
}
header .top-nav-area .top-nav .menu a.social-share::after{
	content:'';
	margin-left: 0;
}

header .top-nav-area .top-nav .menu a.social-share::after li a:after {
	content:'';
}

.social-share-area {
	position: relative;
}


.social-share-menu {
	justify-content: center;
	align-items: center;
	padding: 10px 20px;
	display: none;
	position: absolute;
	top: 100%;
	left: -200%;
	z-index: 1;
	min-width: 180px;
	background: #fff;

	a {
		width: 21px;
    padding: 0 !important;
	}

	li {
		padding: 0!important;

		&:first-of-type {
			margin-left: 0;
		}
	}

	&::after {
		position: absolute;
	    left: 75%;
	    top: -14px;
	    width: 0;
	    height: 0;
	    content: '';
	    border-left: 15px solid transparent;
	    border-right: 15px solid transparent;
	    border-bottom: 15px solid #fff;
	}
}


.social-share-menu.is-dropdown-submenu {
	border: none;
	background-color:#fff;
	min-width: 171px;
	margin-top: 20px;

	&::after {
		position: absolute;
	    left: 75%;
	    top: -14px;
	    width: 0;
	    height: 0;
	    content: '';
	    border-left: 15px solid transparent;
	    border-right: 15px solid transparent;
	    border-bottom: 15px solid #fff;
	}
}

.social-share-menu.is-dropdown-submenu > li {
	width: auto ;
	display: flex;
	text-align: left;
	padding: 9px 7px;

	&:last-child {
		border-right: none;


	}
}



// .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after{
// 	border: none;
// }

// @media screen and (max-width: 39.9375em) {
// 	.social-share-area .accordion-menu li {
// 		width: auto;
// 	}
// }

 /* Medium only */
 @media screen and (min-width: 40em) and (max-width: 63.9375em) {
 	/*.dropdown.menu > li.opens-right > .is-dropdown-submenu {
		left: -134px;
	}*/
 }


/* ====================================================
    Header - desktop
   ==================================================== */
.bannerContainer {
	background-color: #ffedb0;
	text-align: center;
	font-size: 14px;
	margin: 0px;
	padding: 5px 0;
}

.site-nav {
	max-width: 95vw;
}

header {
	position: relative;
	top: 0;
	right:0;
	left:0;
	z-index: 10;
	
	.sticky {
		background-color: rgba(0,0,0, 0.4);
		z-index: 1;
	}
    
	ul {
		list-style:none;
		color: #fff;
		margin:0;

		li {
			display: inline-block;
			margin-bottom:0;
			margin-left: 20px;

			a {
				color: #fff;
				text-decoration: none;
			}

		}
	}
  
	.top-nav-area {
		font-size: 13px;
		color: #494949;
		display: flex;
        justify-content: center;
        align-items: center;
    //padding-top: 10px;

		p {
			//margin-bottom:5px;
			text-align: center;
		}

		.top-nav {
			text-align:right;
			color: #fff;

			.menu a, .menu .button{
				padding: 0 5px;

				&::after {
					content:"|";
					margin-left: 9px;

				}
			}		
		}
	}

	.main-nav-area {
		max-width: 100%;
	}

	.main-nav {
		width: 97%;

		ul {
			text-align: center;
			font-size: 18px;
			text-transform: uppercase;
			line-height: 1.1em;	
			float: right;
			justify-content: space-evenly;
			padding-bottom: 7px;
           
			li a {
								color:$white;
                &:hover {
                    //color:$warning-color;;
                }
                &:focus{
                   // color:$warning-color;;
                }
                &.active{
                   // color:$warning-color;;
                    border-bottom: 4px solid #fb9f28;
                }
             }
             li:hover > a {
                    //color:$warning-color;;
                }	
		}

		.is-dropdown-submenu{
			border:none;
			background-color: rgba($primary-color,0.85);
			font-size:16px;
			text-transform: capitalize;

			li{
				border-bottom: 1px solid #fff;

				&:last-child {
					border-bottom: none;
				}
			}
		}

		.dropdown.menu > li > a, .dropdown.menu > li.is-dropdown-submenu-parent > a{
			padding: 0.7rem 2.5rem;
		}

		.dropdown.menu > li.opens-right > .is-dropdown-submenu{
			top: 124%;
		}

		.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
			display:none;
		}

		.dropdown.menu > li.is-active > a {
			color:#69B8BF;
		}
	}
    .topsmNav{ 
        font-size: 13px;
      
        a {
            color:#9fcf67!important; 
            
        }
    
    }
    .logo{height: 100%;}
    
    
	/*&.hcp {
		.main-nav {
			ul {
				font-size: 15px;

			}

			.dropdown.menu > li.opens-right > .is-dropdown-submenu{
				top: 138%;
			}

			.dropdown.menu > li > a, .dropdown.menu > li.is-dropdown-submenu-parent > a{
				padding: 0.7rem 1.0rem;
			}
			.dropdown.menu > li:last-child > a, .dropdown.menu > li:last-child.is-dropdown-submenu-parent > a{
				padding-right: 0;
			}
			.dropdown.menu > li:first-child > a, .dropdown.menu > li:first-child.is-dropdown-submenu-parent > a{
				padding-left: 0;
			}
		}
	}*/
    
    
    
}



/* Small & Medium only */
@media screen and (min-width: 0em) and (max-width: 63.9375em) {
	header .main-nav ul {
		float: none;
	}
}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 1115px) {
	header .main-nav .dropdown.menu > li > a, header .main-nav .dropdown.menu > li.is-dropdown-submenu-parent > a{
		padding: 0.7rem 1.9rem;
	}

	
}


/* ====================================================
    Header - mobile
   ==================================================== */

/* Small & Medium only */
@media screen and (min-width: 0em) and (max-width: 63.9375em) {
    
     .hero.sub{
		    padding-top: 15vh;
         h1{
             font-size:35px;
             span{
                 font-size:35px;
             }
         }
        }
    
    
	.top-nav {
		margin-top: 10px;
	}
	.main-nav-area {
		width: 100%;
		max-width: 100%;
        display: none;
		.mobile-nav {
			float: right;
		}

		.main-nav {
			background-color: $primary-color;
			margin-left:0;
			margin-right: 0;
			width: 100%;
			margin-top: 15px;
			padding-top: 0;

			.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
				border-color: #fff transparent transparent;
			}

			ul {
				text-align: left;

				ul li {
					background-color:#69B8BF;
					text-transform: capitalize;

					a:hover, a.active {
						color:$primary-color;
					}


				}

			}

			.extra-nav {
				background-color: #2f2f2f;
				text-transform: capitalize;
				font-size: 15px;
			}


		}
	}

    .color-blocks {
       margin-top: 20px;
       height:auto;
        padding-bottom: 80px;
	    .externalLink {
            width:80vw;
            margin: 20px auto;
            margin-bottom: 0px;
            font-size: 1.2em;
            padding:10px 15px;
            position: relative;
              
	}
}
   
    .mtop20{   margin-top: 0px;}
    
   
}

.hamburger-container {
  width: 100%;
  height: 9.1vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menu-hb {
  display: grid;
  place-items: center;
  height: 40px;
  width: 50px;
	cursor: pointer;
	
	& > span {
    width: 50px;
    height: 3px;
    background: #FFF;
    display: block;
    transition: all 0.3s ease-in-out;
  }
}

.line-1 {
  width: 42px;
  justify-self: end;
}

.line-2 {
  width: 35px;
  justify-self: end;
}

/* ====================================================
    Hero
   ==================================================== */

.hero {
	color:#fff;
    min-height: 900px;
	//padding-top: 18%;
	//margin-bottom: 40px;
	z-index: 1;
	position: relative;
 
	margin-top: -16.9vh;
    


	&.hero-expanded {
		.grid-container {
			max-width: 80rem;
		}
	}

	h1 {
		//font-weight:700;
		//color:#88E3EB;
		font-size: 35px;
		line-height: 1.1em;
		//margin-top: 103px;
		
		margin-bottom: 0.8rem;

		a {
			text-decoration: none;
			color:#88E3EB;
		}
	}

	.footnote{
		text-shadow: 0px 2px 12px #000;
	}

	a {
		color:#fff;
        text-decoration: none;
	}

	.button {
		font-size: 18px;
		box-shadow: 0px 2px 16px #000;
		background-color: $alert-color;
		color:#000; 
   
		&.small {
			font-size: 13px;
			padding: 0.5em 1.2em;
		}
	}

	ul span {
		color:#fff;
	}

	&.sub {
		margin-bottom:0px;
		padding-top: 20vh;
		
        &.coupon{
            min-height: 700px;
        }
		h2 {
			font-weight:400;
			color:#fff;
			font-family: 'Roboto Condensed', sans-serif;
			margin-top: 115px;
			font-size: 35px;
			line-height: 1.1em;
			text-shadow: 0px 2px 12px #000;
		}

		&.three-boxes {
			padding-top: 145px;
		}
        &.children{
             min-height: 700px;
            background-image: url('../img/4.jpg'); 
            background-repeat: no-repeat; 
            background-position: top center;
            background-size:100% auto;
        }
         &.adult{
            min-height: 700px;
            background-image: url('../img/1.jpg'); 
            background-repeat: no-repeat; 
            background-position: top center;
            background-size:100% auto;
        }
         &.contact{
             min-height: 900px;
            background-image: url('../img/2.jpg'); 
            background-repeat: no-repeat; 
            background-position: top center;
            background-size:100% auto;
        }
        &.coupon{
            min-height: 700px;
            background-image: url('../img/2.jpg'); 
            background-repeat: no-repeat; 
            background-position: top center;
            background-size:100% auto;
            h2{display: none;}
            h1{
                margin-top:0px;
                font-weight: 700;
            }
        }
	}

	&.sub-sub {
		margin-bottom: 0px;
        padding-top: 180px;
        min-height: 400px;
        background-image: url('../img/2.jpg'); 
            background-repeat: no-repeat; 
            background-position: top center;
            background-size:100% auto;
	}
}

.sub-header {
	font-weight: 900;
	font-size: 15px;
}

.orange-link {
	color: $orange;

	&.second {
		margin-bottom: 16px;
		display: block;
	}

	&:hover {
		color: $orange;
	}

	&.underline {
		text-decoration-color: $orange;
	}
}

.privacy {
	p {
		font-size: 15px;
		margin-bottom: 12px;
	}

	h2, h3 {
		font-family: 'Roboto Condensed', sans-serif;
		color: $green;
		margin-top: 40px;
		margin-bottom: 0;
	}
}

.privacy-list {
	ul {
		margin-bottom: 30px;
	}

	li {
		font-size: 15px;
	}

	li:not(:first-child){
		padding-left: 20px;
	}

	strong {
		font-weight: 900;
	}
}

.privacy-list-no-indent {
	ul {
		margin-bottom: 30px;
	}
	
	li {
		font-size: 15px;
	}

	strong {
		font-weight: 900;
	}
}

.legal-notice {
	font-size: 15px;
}

.underline {
	text-decoration: underline;
}

.spacing-bottom-20 {
	margin-bottom: 20px !important;
}

.trans-box {
	background-color: rgba(0,124,133, 0.75);

	.orange {
		background-color: rgba(250,164,46, 0.85);
		position: relative;
    left: -10px;
	}

	h4 {
		color:#fff;
		font-family: 'Roboto Condensed', sans-serif;
		margin-bottom: 0;
		font-weight: 700;
	}

	img {
		margin-bottom: 5px;
	}

    &.sm { 
        position: relative;
       // width:19%!important;
        margin-left: 5px;    
        padding:50px 20px 70px 20px;
        text-align: center;
        font-size: 1.2em;
        line-height: 1.3em;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 500;
        &.ch{
           width:19%!important;
        }
        .button.small{
            position: absolute;
            bottom:10px;
            width: 80%;
            font-size: .8em;
            left:10%;
            padding: .5em .5em;
            box-shadow: none;
            
        }
    }

	&.box1 {
		font-size: 14px;
		padding: 15px 15px 5px;
		max-width: 310px;
		float: left;

		h2 {
			color:#fff;
			font-family: 'Roboto Condensed', sans-serif;
			font-weight: 400;
		}
		h4 {
			font-weight: 400;
		}

	}

	&.box2 {
		padding: 30px 30px 30px;
		//position:absolute;
		text-align: left;
		font-size: 14px;

        &.home{
					//top: 25vh;
					//width: 85%;
					padding: 0;
                
				}
				
				&.contact {
					//padding-top: 20vh;
					//width:45%;
					//padding:0px;
				}
        
        p {
					color:$white;
					line-height: 1.4;
            font-size: 1.2em;
        }
		.btn-bottom {
			//position: absolute;
			bottom:30px;
			right: 0;
			left: 0;
			width: 100%;

			.button.small {
				box-shadow: none;
			}
			
		}

		.row1 {
			height:30px;
		}

		.cpay {
			text-align: left;
			padding: 20px 0px 20px 20px;
            //width:70%;
		}

		.row3 {
			padding:20px;
		}
	}
}

.contact-box{ padding-top: 8vh;}

.coupon-click{
    
    padding-top: 30vh;
    color:#FFFFFF!important;

}
.padB20{padding-bottom: 20px!important;}
.contact-h2 {
	text-shadow: none !important;
	padding: 10px 15px;
	margin: 0 !important;
}

.contact-p {
	padding-left: 15px;
	margin-bottom: 4px;
	font-weight: 400;
}



/* Small only */
@media screen and (max-width: 39.9375em) {


	.hero {
		margin-bottom: 0px;
		padding-top: 150px;

		h1 {
			margin-top: 0;
		}

		&.sub h2{
			margin-top: 50px;
            font-size: 1.7em;
            
		}
        &.sub.children{
            
            background-image: url('../img/header-child-mbl.jpg'); 
            background-repeat: no-repeat; 
            background-position: 0 0;
            background-size:100% auto;
        }
        &.sub.adult{
            background-image: url('../img/header-adult-mbl.jpg'); 
            background-repeat: no-repeat; 
            background-position: top center;
            background-size:100% auto;
        }
        &.sub.contact{
            background-image: url('../img/header-contact-mbl.jpg'); 
            background-repeat: no-repeat; 
            background-position: top center;
            background-size:100% auto;
        }
        &.sub.coupon{
            background-image: url('../img/header-contact-mbl.jpg'); 
            background-repeat: no-repeat; 
            background-position: top center;
            background-size:100% auto;
            h2{display: none;}
            h1{
                margin:10px 30px;
                font-weight: 700;
                font-size: 35px;
                span{
                font-size: 38px;  
                }
                
            }
            .bordered-card{ 
            margin:10px auto;}
        }
        &.sub-sub { 
            background-image: url('../img/header-contact-mbl.jpg'); 
            background-repeat: no-repeat; 
            background-position: top center;
            background-size:100% auto;
        }
        
	}
	.trans-box.box1 {
		max-width: 100%;
	}
	.trans-box.box2 {
		margin-bottom: 20px;
       // min-height: 50vh;
		.btn-bottom {
			position: inherit;
             .small{
                font-size: 1.2em;
            }
		}
        p{
            font-size: 1.2em;
        }
	} 
    .trans-box.sm.ch{
        width:80%!important;
    }
    .trans-box.sm .button.small{
        
    }
    
    .trans-box.box2 .cpay{
        padding:0px;
    }
    
    .contact-box{ 
        padding-top: 0px;
    }
    .coupon-click{
    
          padding-top: 1vh;
        .cpay{ 
            padding:20px!important;
        }
  

}
}


.video-embed {
	position: relative;
	height: 74.6vh;
	margin-bottom: 0rem;
}
.video-embed video{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	height: 85.3vh;
	width:100%;
	object-fit: cover;
}
.video-embed .hero-img{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	height: auto;
}



/* ====================================================
    Boxes
   ==================================================== */
.gray-box {
	background-color:#E0E0E0;
	text-align:center;
	font-size: 15px;
	padding: 30px 10px 0px;

	img {
		margin-bottom: 15px;
	}

	.button.small {
		margin-bottom: -15px;
	}
}

.copay{
	background-image: url('../img/img-copay.jpg'); 
	background-repeat: no-repeat; 
	background-position: top center;
	background-size: cover;
	padding-top: 65px;
	padding-bottom: 50px;
	color:#fff;

	h3{
		font-size: 28px;
		font-weight: 400;
		font-family: 'Roboto Condensed', sans-serif;

		a {
			text-decoration: none;
			color:#69B8BF;
		}
	}

	.footnote{
		font-size: 15px;
	}

	a {
		color:#fff;
	}
}



.duet-trials {
	color: $primary-color;
	font-weight: 700;
	font-size: 40px;
	font-family: 'Roboto Condensed', sans-serif;
	text-align: center;

	.tbox {
		background-color: $primary-color;
	}

	.gbox{
		background-color: #666;
	}

	p {
		margin-bottom:0;

		&.big {
			font-size: 26px;
			font-weight: bold;
		}
	}

	.duet-trials-box {
		font-family: Arial, sans-serif;
		font-size: 18px;
		color:#fff;
		padding: 20px;
		font-weight: normal;
		height: 100%;
		background-image: url('../img/logo_watermark_white.svg'); 
		background-position: top center;
		background-repeat: no-repeat;
	}
}

/* ====================================================
    Tables
   ==================================================== */
table {
	font-weight: bold;
	border-bottom: 5px solid $primary-color;
	border-top: 5px solid $primary-color;
	box-shadow: 0px 3px 39px rgba(0,0,0,0.30);
	margin-top: 10px;

	thead {
		font-family: 'Roboto Condensed', sans-serif;
		font-weight: 700;
		border-bottom: 2px solid $primary-color;

		th {
			text-align: center;
		}
	}

	tbody{
		background-color: inherit;
	}

	tbody th, tbody td{
		padding: 0.8rem 0.625rem 0.925rem
	}

	td + td {
		font-size: 25px;
		text-align: center;
	}

	&.table-nostyle{
		margin:0;
		border: none;
		box-shadow: none;

		tbody {
			border: none;

			td {
				padding-top: 0.4rem;
				padding-bottom: 0.525rem;
			}

			tr:nth-child(even){
				background-color: inherit;
			}

		}

	}
}

table.hover:not(.unstriped) table.table-nostyle tr:nth-of-type(even):hover {
	background-color: inherit;
}

.duet-trials table.disorders {
	font-size: 28px;
	color:$secondary-color;

	tr{
		border-bottom: 1px solid $primary-color;
	}

	td {
		vertical-align: top;
		font-family: Arial, sans-serif;
	}

	td + td {
		font-size: 18px;
		text-align: left;
		font-weight: normal;
		line-height: 1.3em;
		color:#000;
	}
	tbody tr:nth-child(even){
		background-color:inherit;
	}
}

/* ====================================================
    Graphs
   ==================================================== */
.graph-area {
	background-color:#fff;
	box-shadow: 0px 3px 35px rgba(0,0,0,0.30);
	padding: 30px 40px;
	text-align: center;
	margin-bottom: 2rem;
}


/* ====================================================
    Tabs
   ==================================================== */
.tab-area {
	background-color:#fff;
	box-shadow: 0px 3px 35px rgba(0,0,0,0.30);

	.tabs {
		background: inherit;
		border: none;

		li {
			margin-bottom: 0;
		}

		.tabs-title > a {
			text-decoration: none;
			font-size: 16px;
		}

		.tabs-title > a:focus, .tabs-title > a[aria-selected='true']{
			background: $primary-color;
			color: #fff;
		}
	}

	.tabs-content {
		border: none;
		background: inherit;

		.tabs-panel{
			padding: 2rem;
		}

		table {
			box-shadow: none;
		}
	}
}


/* ====================================================
    SWF video
   ==================================================== */
.responsive-embed, .flex-video {
	padding-bottom: 57%;
}

/* ====================================================
    Buttons
   ==================================================== */
.button {
    text-decoration: none;
   // text-transform:uppercase;
    background-color: $alert-color;
    color:#000; 
    &:after {
    	content: '\25b6';
    	margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
	
    }
    :hover{}
}

.button.secondary {
    background-color: $secondary-color;
    color: #000; 
}
.button.secondary:hover,
.button.secondary:focus {
  background-color: $secondary-color;
	color: #fff; 
}


/* ====================================================
    Reveal
	 ==================================================== */
	 
.reveal {
	padding: 3rem;
}

.reveal.large {
	max-width: 50em;
}

/* ====================================================
    Left Column
   ==================================================== */

.left-side-box-inside {
	ul li:before {
		content: "\25A0";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: $success-color; /* Change the color */
		font-weight: bold; /* If you want it to be bold */
		display: inline-block; /* Needed to add space between the bullet and the text */ 
		width: 1em; /* Also needed for space (tweak if needed) */
		margin-left: -1em; /* Also needed for space (tweak if needed) */
	}
}

/* ====================================================
	ISI Section
==================================================== */

.sideisi-section {
	position: fixed;
	padding: 40px 10px;
	background-color: rgba(240,240,240,0.84);
	// width: 25%!important;
	max-width: 370px!important;
	height: 84%;
	min-height: 550px;
	top: 15vh;
	right: 13%;
	overflow-y: auto;
	z-index: 1000;
	
	ul li:before {
		content: "\25A0";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		color: $success-color; /* Change the color */
		font-weight: bold; /* If you want it to be bold */
		display: inline-block; /* Needed to add space between the bullet and the text */ 
		width: 1em; /* Also needed for space (tweak if needed) */
		margin-left: -1em; /* Also needed for space (tweak if needed) */
	}
	
	a {
	color: $success-color !important;
	}
	
	.close_btn {
		position: absolute;
		right:0px;
		top:0px;
	}
	
	&.home {
		height:53.05%;
		position: absolute !important;
		display: block;
		right: 7%;
	}

	&.contact {
		height:73.4%;
		right: 7%;
		position: absolute !important;
		display: block;
	}

	p {
		width: 90%;
    font-size: 16px;
    margin: 10px auto;
	}
}


.isi-section {
	font-size: 15px;
	background-color: rgba(255,255,255,1);
	box-shadow: 0px 3px 39px rgba(0,0,0,0.16);
	padding-top: 120px;
	padding-bottom: 30px;
    
    ul li:before {

      content: "\25A0";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: $success-color; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */ 
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */

    }
    a{
    color: $success-color !important;
    }
}

#non-sticky-isi {
	background-color: #fff;

	ul {
		li {
			display: list-item;
			border-right: none;
		}
	}

	p {
		color: #4e4e4e !important;
	}
}

#sticky-isi { 
    position: fixed; 
    height:25%;
    z-index:1000;
    overflow-y: auto;
    bottom: 0;
    right:0px;
    left:0px;

    &.isi-section {
    	padding-top: 10px;

    	.sIND {
    		position:relative;

    		.isi-btn {
	    		position:absolute;
	    		right:0;
	    		text-decoration: none;
	    		color: $primary-color;
	    		font-size: 28px;
	    	}
    	}

    	&.mob_isi{  
        	max-height:75%;
        	height:auto;
        	padding-bottom: 0;

        	h5{
        		margin-bottom: 0;
        	}

        	.content_box {
	          padding-top: 10px;
	          height: 75px;
	          
	        }

	        .s_header{
	            font-size: 1.1em;
	            position:relative;

	            &:after{
	            content: "+";
	            font-size: 1.5em;
	            position: absolute;
	            right: 2px;
	            top:0;
	            color: $primary-color;
	            }
	            &.isiOn:after  {
	               content:"-" ;
	            }  
	                
	         }

	        .sISI{ 
	        	overflow-y: hidden;
	        	border-top: 1px solid #999;
	        	padding-top: 5px;
	        	

	            &.On{
	                height:70%;
	                overflow-y: hidden;
	                
	                .content_box{
	                    height:40vh;
	                    overflow-y: auto;
	                }
	            }
	        }

	        .sIND{
	        	overflow-y: hidden;
	        	
	        	margin-bottom: 10px;

	            &.On{
	                height:79%;
	                overflow-y: hidden;
	                
	                .content_box{
	                    height:11vh;
	                    overflow-y: auto;
	                }
	            }
	        }


        }
    }
}


.page-ISI {
	 h5 {
	 	display: none;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight:400;
	 }
	 h6{
	 	line-height: 1.1em;
		font-size: 24px;

		sup{
			top: -0.5em;
		}
	 }
}

.isi-box{
	border: #606060 2px solid;
	padding: 9px 10px 0px;
	margin-bottom: 12px;
	width: 83%;
	margin-left: auto;
	margin-right: auto;

	p {
		width: 100%;
	}

	strong {
		font-weight: 900;
		width: 100%;
    display: block;
	}
}

/* ====================================================
    Footer Section
   ==================================================== */

footer {
	font-size: 13px;
	padding-top: 40px;
	padding-bottom: 140px;
	background-color: #333333;

	&.home-footer {
		//margin-top: 10.7vh;

		/*.filler-1 {    
			width: 100%;
			height: 11vh;
		}

		.filler-2 {
			width: 100%;
			height: 2vh;
		}*/
	}

	&.contact-footer {
		/*.filler-1 {
			width: 100%;
			height: 11vh;
		}

		.filler-2 {
			width: 100%;
			height: 2vh;
		}*/
	}

	ul {
		list-style:none;
		margin:0;

		li{
			display: inline-block;
			border-right: 1px solid $black;
			padding-right: 10px;
			padding-left: 5px;

			&:first-child{
				padding-left: 0;
			}
			&:last-child{
				padding-right: 0;
				border-right: none;
			}

		}
	}
    a {
                color:#ffb95f!important;    

				&:hover{
					color: #ffb95f;
				}
			}
    
    p{
        color:$white!important;
    }
    
    .footerlogo{ margin-top:20%; margin-right:7%;}

}
/* ====================================================
   main content
   ==================================================== */

.coupon-click{display: block;}

.childrepage {
	padding-left: 7.5rem;
	padding-right: 7.5rem;
	box-sizing: content-box;
    h1{
       margin-top: 10px!important; 
    }
	h4 {
		background-color: #FF9D28;
		text-align: center;
		padding:10px 0px;
		color:#FFFFFF;
		font-size: 1.3em;
	}
}

.creambg {
	background: $cream;
}

.border-box {
	box-sizing: border-box;
}

.padded-sides {
	padding-left: 7.5rem;
	padding-right: 7.5rem;
	box-sizing: content-box;
}



/* ====================================================
    Responsive Styling
   ==================================================== */

/* Small only */
@media screen and (max-width: 39.9375em) {
	.logo {
		width: 100%;
	}

	.menu.align-center li {
		margin-bottom: 5px;
	}
	

	.reminderIcon .large-12 {
		margin-top: 30px;
	}

	.step-dose {

		.icon-steps {
			background-color: $primary-color;
			color:#fff;
			font-weight: bold;
			text-transform: uppercase;
			padding-top: 12px;
			padding-bottom: 12px;
			text-align: center;
			font-size: 15px;
			border-radius: 100px;

			p {
				margin-bottom: 0;
			}

			&.red {
				background-color: $secondary-color;
			}
		}
	}
    
    .trans-box.sm{
        width:80%!important;
        margin: 10px auto;
        padding:20px 20px 80px 20px;
        float:left;
    }
    
    //==================
    .padded-sides {
		padding-right: 0.9375rem;
    padding-left: 0.9375rem;
	}

	.social-share-menu {
		left: -200%!important;
		padding: 10px 20px 0px;
    top: 170%!important;
	}

	.trans-box.home {
		top: 10vh!important;
		width: 85%!important;
        margin:0px auto;
	}

	.main-nav-area {
        display: none;
	}

	.hero {
        margin-top: -19.8vh;
         min-height: 900px;
        &.home{
            min-height: 1180px;
        }
        &.sub.contact{
            min-height: 730px!important;
        }
	}

	header .top-nav-area {
		justify-content: space-between;
	}

	/*.sideisi-section, #non-sticky-isi, .filler-1, .filler-2 {
		display: none!important;
	}*/

	footer {
		padding-bottom: 6vh;
	}

	.video-embed {
		height: 73.3vh;

		video {
			height: 73.3vh;
		}
	}

	footer.home-footer {
		margin-top: 0vh;
		//padding-bottom: 35vh;
	}

	.trans-box.box2 {
		//left: 50%;
       // transform: translate(-50%, -40%);
		position: relative;
		width: 75%;

		&.contact {
        
		//	top:30px;
			width: 80%;
            margin:10px auto;
		}
	}

	#sticky-isi {
		//sdisplay: block !important;
	}

	.adult-resources {
		padding: 2rem 0.9375rem !important;
	}
    .bl{border-right:none;}
    
    .trans-box.box2.contact {
        //margin-top: 10%;
		width: 90%;   
        height: 58vh;
	}
    
    header ul li {
		margin-left: 0;
	}

	.contact-footer {
		margin-top: -8.7vh;
    padding-top: 12vh;
	}

    
}

.mobile-drop {
	position: relative;
	display: none;
    margin-left: 0px!important;

	ul {
    position: absolute;
    background-color: #e9a43d;
    width: 100vw;
    top: 0px;
    left:0px;    
		
		li {
			width: 90vw;
			text-align: center;
			font-size: 1.1rem;
			padding: 15px 10px;
            border-bottom: 1px #fff solid;
            margin-left: 5vw;
            color:#292929;
            &:last-child
            {
              border-bottom: none;
            }
            &.dark a{
                color:#323232!important;
            }
		}
	}
}

/* ====================================================
    SweetAlert Modal
   ==================================================== */
.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, .75) !important;
}

.swal2-popup {
  width: 48em !important;
  border-radius: 0px !important;
  background-color: #ffffff !important;
  padding-bottom: 35px !important;
  padding:1.25em 2em 2em !important;

}

.swal2-popup #swal2-content p{
  text-align:center !important;
}

.swal2-popup .swal2-title {
  color: $secondary-color !important;
    font-size: 40px !important;
    font-weight: 400 !important;
    margin: 10px 30px 20px !important;
    text-align:center !important;
	
}
.swal2-popup .swal2-content{
  font-size: 18px !important;
  color:#000 !important;
  line-height: 1.1em !important;
  margin: 0px 20px 0px !important;
  font-weight: normal !important;

}

.swal2-popup .swal2-actions {
  margin-top: 0px !important;
}

.swal2-popup .swal2-close, .swal2-popup .swal2-close:hover {
  -webkit-transform: none !important;
  transform: none !important;
  color: #969696 !important;
  font-weight:bold !important;
  box-shadow: none !important;
}

.swal2-popup .swal2-styled.swal2-confirm, .swal2-popup .swal2-styled.swal2-cancel {
  	border-radius: 30px !important;
  	font-size: 17px !important;
	background-color: $secondary-color !important;
	box-shadow: none !important;
	border: none !important;
	padding: 10px 20px 8px !important;
	text-transform:uppercase;

	&:after {
		content: '\25b6';
		margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
	}
}


.swal2-popup .swal2-styled.swal2-cancel {
    background-color: #ccc !important;
    color: #666 !important;
    box-shadow: none !important;
    margin-left: 40px;
}


.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none !important;
}

@media screen and (max-width: 1440px) {

	.sideisi-section {
		right: 5%;
	}

}

@media screen and (max-width: 1390px) {

	header .main-nav ul li a {
		font-size: 16px;
	}

	/*.shorten-p-1, .shorten-p-2 {
    width: 100%;
	}
*/
}

@media screen and (max-width: 1275px) {

	header .main-nav ul li a {
		font-size: 15px;
	}

}

@media screen and (max-width: 1250px) {

	.sideisi-section  {
		right: 0;
	}

}

@media screen and (max-width: 1215px) {

	header .main-nav ul li a {
		font-size: 14px;
	}

	.site-nav {
    max-width: 100vw;
	}

	

	.childrepage {
		padding-right: 0.9375rem;
    padding-left: 0.9375rem;
	}

}

@media screen and (max-width: 1120px) {

	header .topsmNav {
    font-size: 12px;
	}

	.sideisi-section {
		right: 0% !important;
	}

	.main-nav-area{
		.large-9 {
			width: calc(75% + 1.875rem);
		}
	}
}



@media screen and (max-width: 320px) {

	.contact-footer {
		margin-top: -16.7vh;
    padding-top: 16vh;
	}

}







@media screen and (max-width: 767px) {

  .swal2-popup {
    padding:1.25em 1em 3em !important;

    &.HCP_popup {
      background-position: top 40px left 35px;
    }
  }

  .swal2-popup .swal2-title { font-size: 24px !important; }

  .sweet-alert p { font-size:18px; }

  .swal2-popup .swal2-styled.swal2-confirm, .swal2-popup .swal2-styled.swal2-cancel{ width: 85%;}

  .swal2-popup .swal2-styled.swal2-cancel {
    margin-left:0px;
    margin-top: 20px;
  }

}